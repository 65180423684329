import { isPlatformBrowser } from '@angular/common';
import { ElementRef, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ScriptService, Scripts } from '../../../core/services/scripts.service';

declare const shaka: any;

enum PlayerTech {
    SHAKA = 'shaka',
    DASH = 'dash'
}

enum PlayerManifest {
    DASH = 'dash',
    HLS = 'hls'
}

export const PLAYER_SPEEDS = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

// <button type="button" class="plyr__control plyr__control--overlaid" aria-label="Play, {title}" data-plyr="play">
// <a class="icon--not-pressed" xlink:href="#plyr-play"><ion-icon name="play"></ion-icon></a>
// <a class="icon--pressed" xlink:href="#plyr-pause"><ion-icon name="pause"></ion-icon></a>
// </button>

const controls = `
<div class="plyr__controls">
    <div>
    </div>
    <div class="plyr__controls-bottom">
      <div class="inner-controls">
        <div class="plyr__time plyr__time--current" aria-label="Current time">0:00</div>

        <div class="right-controls">
          <div class="plyr__time plyr__time--duration" aria-label="Duration">0:00</div>
        </div>
      </div>

      <div class="plyr__progress">
        <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
        <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
        <span role="tooltip" class="plyr__tooltip">0:00</span>
      </div>
    </div>
</div>
` as any;

@Injectable()
export class VideoPlayerService {
    private playerTech: PlayerTech;
    private playerManifest: PlayerManifest;
    private plyrPlayer: any;
    private shakaPlayer: any;
    private shakaProbe: any;
    private dashPlayer: any;
    private seenSegments: boolean[] = [];
    private controlsShown: boolean = false;
    private hfrQualities = [1080, 720];
    private allQualityOptions = ['Auto', 1080, 720, 480, 360];
    private playerOptions: any = {
        // controls,
        speed: {
            selected: 1,
            options: PLAYER_SPEEDS
        },
        fullscreen: {
            enabled: true,
            fallback: true,
            iosNative: true
        },
        seektime: 10,
        ratio: '16:9',
        autoplay: true,
        previewThumbnails: {
            enabled: true,
            src: `${environment.rootCDN}/thumbs_timeline/dota_-H4-lAao3/100p.vtt`
            // aspectRatio: 1.777777
        },
        keyboard: {
            global: true
        },
        iconUrl: '/assets/lib/plyr/plyr-3.6.8.svg',
        quality: {
            default: 1080,
            options: this.allQualityOptions,
            forced: true,
            onChange: quality => {
                if (!this.shakaPlayer || !this.shakaProbe) {
                    this.selectedQuality = quality;

                    return;
                }

                const variantTracks = this.shakaPlayer.getVariantTracks();
                let track;

                if (
                    this.playerManifest === PlayerManifest.DASH &&
                    this.hfrQualities.includes(quality)
                ) {
                    track = variantTracks
                        .sort((t1, t2) => t2.bandwidth - t1.bandwidth)
                        .find(t => t.frameRate >= 50 && t.height === quality);
                } else {
                    track = variantTracks
                        .sort((t1, t2) => t2.bandwidth - t1.bandwidth)
                        .find(t => t.height === quality);
                }

                if (track) {
                    this.shakaPlayer.configure({
                        streaming: {
                            bufferingGoal: 300,
                            bufferBehind: 300
                        },
                        abr: {
                            defaultBandwidthEstimate: 1000000,
                            // bandwidthDowngradeTarget: 0.95,
                            // bandwidthUpgradeTarget: 0.85,
                            enabled: false
                        }
                    });

                    if (quality > this.selectedQuality) {
                        this.shakaPlayer.selectVariantTrack(track, true, 0);
                    } else {
                        this.shakaPlayer.selectVariantTrack(track);
                    }

                    this.selectedQuality = quality;
                }
            }
        }
    };
    private selectedQuality: number;

    // Subjects
    private controlsVisibilitySubject: Subject<boolean> = new BehaviorSubject<boolean>(true);
    private fullscreenSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
    // Sent when playback of the media starts after having been paused; that is, when playback is resumed after a prior pause event.
    private playEventSubject: Subject<any> = new Subject<any>();
    // Sent when the media begins to play (either for the first time, after having been paused, or after ending and then restarting).
    private playingEventSubject: Subject<any> = new Subject<any>();
    private pauseEventSubject: Subject<any> = new Subject<any>();
    private errorEventSubject: Subject<any> = new Subject<any>();
    private waitingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private endedSubject: Subject<any> = new Subject<any>();
    private qualityChangedSubject: Subject<number> = new Subject<number>();
    private speedChangedSubject: Subject<number> = new Subject<number>();
    private timeUpdateSubject: Subject<any> = new Subject<any>();
    private volumeChangeSubject: Subject<number> = new Subject<number>();

    // External observables
    public controlsVisible$: Observable<boolean> = this.controlsVisibilitySubject.asObservable();
    public fullscreen$: Observable<boolean> = this.fullscreenSubject.asObservable();
    public playEvent$: Observable<any> = this.playEventSubject.asObservable();
    public playingEvent$: Observable<any> = this.playingEventSubject.asObservable();
    public pauseEvent$: Observable<any> = this.pauseEventSubject.asObservable();
    public errorEvent$: Observable<any> = this.errorEventSubject.asObservable();
    public waiting$: Observable<any> = this.waitingSubject.asObservable();
    public ended$: Observable<any> = this.endedSubject.asObservable();
    public quality$: Observable<number> = this.qualityChangedSubject.asObservable();
    public speed$: Observable<number> = this.speedChangedSubject.asObservable();
    public timeUpdate$: Observable<number> = this.timeUpdateSubject.asObservable();
    public volume$: Observable<number> = this.volumeChangeSubject.asObservable();

    constructor(
        private scriptService: ScriptService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {}

    private browserSupportsVP9WebmDash() {
        // console.log('calling browser support check...');
        // console.log('browser support', this.shakaProbe);

        // Safari can't play back VP9 webm but the probe reports
        // that it actually CAN. Therefore, we test for Safari explicitly
        const isSafari =
            navigator.vendor &&
            navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') == -1 &&
            navigator.userAgent.indexOf('FxiOS') == -1;

        return (
            !isSafari &&
            (this.shakaProbe.manifest['application/dash+xml'] || this.shakaProbe.manifest.mpd) &&
            this.shakaProbe.media['video/webm; codecs="vp9"']
        );
    }

    public async initialize(element: ElementRef) {
        if (isPlatformBrowser(this.platformId)) {
            const Plyr = (await import('@gameleap/plyr')).default;
            this.plyrPlayer = new Plyr(element.nativeElement, this.playerOptions);
        } else {
            return;
        }

        await Promise.all([
            this.scriptService.load(Scripts.PLAYER),
            this.scriptService.load(Scripts.PLAYER_MUX),
            this.scriptService.loadScript(Scripts.RANGETOUCH)
        ]);

        this.plyrPlayer.on('controlshidden', () => {
            this.controlsShown = false;
            this.controlsVisibilitySubject.next(false);
        });
        this.plyrPlayer.on('controlsshown', () => {
            this.controlsShown = true;
            this.controlsVisibilitySubject.next(true);
        });
        this.plyrPlayer.on('timeupdate', e => {
            this.setSeenPercentage(e.detail.plyr.currentTime, this.plyrPlayer.duration);
        });
        this.plyrPlayer.on('play', e => this.playEventSubject.next(e));
        this.plyrPlayer.on('playing', e => {
            this.playingEventSubject.next(true);
            this.pauseEventSubject.next(false);
            this.waitingSubject.next(false);
        });
        this.plyrPlayer.on('waiting', e => {
            this.waitingSubject.next(true);
        });
        this.plyrPlayer.on('pause', e => {
            this.pauseEventSubject.next(true);
            this.playingEventSubject.next(false);
        });

        this.plyrPlayer.on('error', e => this.errorEventSubject.next(e));
        this.plyrPlayer.on('enterfullscreen', e => this.fullscreenSubject.next(true));
        this.plyrPlayer.on('exitfullscreen', e => this.fullscreenSubject.next(false));
        this.plyrPlayer.on('ended', e => this.endedSubject.next(true));
        this.plyrPlayer.on('qualitychange', e => {});
        this.plyrPlayer.on('ratechange', e => {
            if (this.plyrPlayer.speed !== 0) {
                this.speedChangedSubject.next(this.plyrPlayer.speed);
            }
        });
        this.plyrPlayer.on('volumechange', e => {
            this.volumeChangeSubject.next(this.plyrPlayer.volume);
        });
        this.seenSegments = [];

        await this.determinePlayerTech(element);
        return this.plyrPlayer;
    }

    public destroy() {
        this.plyrPlayer.destroy();
    }

    public loadManifest(
        playlist: { dash: string; hls: string; noSound: string },
        startTime?: number,
        noSound?: boolean
    ): Promise<void> | Promise<any> {
        // console.log(document.createElement('video').canPlayType('video/webm'));
        // console.log('loadManifest called');

        return new Promise(async (resolve, reject) => {
            // console.log('I am testing browser vp9 support now...');

            if (noSound === true) {
                try {
                    this.shakaPlayer.load(playlist.noSound);
                } catch (error) {
                    console.warn('Could not load manifest.', error);
                }
                this.playerManifest = PlayerManifest.HLS;
            } else if (this.browserSupportsVP9WebmDash()) {
                // console.log('browser supports webm dash!!!');

                try {
                    await this.shakaPlayer.load(
                        playlist.dash
                        // 'https://cdn.test-gameleap.com/videos_us-east-1/av1/ow_video_crf48_keyint240/manifest.mpd'
                        // 'https://cdn.test-gameleap.com/videos/old/apex_-YfU9UGXI/dash/apex_-YfU9UGXI.mpd'
                        // 'https://bitmovin-a.akamaihd.net/content/MI201109210084_1/mpds/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.mpd'
                    );
                } catch (error) {
                    console.warn('Could not load manifest.', error);
                }
                this.playerManifest = PlayerManifest.DASH;
            } else {
                // console.log('browser DOES NOT SUPPORT webm dash!!!');

                try {
                    this.shakaPlayer.load(playlist.hls);
                } catch (error) {
                    console.warn('Could not load manifest.', error);
                }
                this.playerManifest = PlayerManifest.HLS;
            }

            if (this.plyrPlayer) {
                this.plyrPlayer.off('loadedmetadata', resolve);
                this.plyrPlayer.on('loadedmetadata', resolve);
            }
            this.seenSegments = [];
        });
    }

    public setPreviewThumbnails(timelineThumbnailsPaths: string[]) {
        this.plyrPlayer.loadPreviewThumbnails(timelineThumbnailsPaths);
    }

    public async play(seconds?: number) {
        await this.plyrPlayer.play();

        if (seconds) {
            setTimeout(() => {
                this.setTime(seconds);
            }, 250);
        }
    }

    public pause() {
        this.plyrPlayer.pause();
    }

    public toggleControls(value?: boolean) {
        this.plyrPlayer.toggleControls(value || !this.controlsShown);
    }

    public setTime(seconds: number) {
        this.plyrPlayer.currentTime = seconds;

        if (this.playerTech === PlayerTech.DASH) {
            this.dashPlayer.seek(seconds);
        }
    }

    public rewind(seconds?: number) {
        this.plyrPlayer.rewind(seconds);
    }

    public forward(seconds?: number) {
        this.plyrPlayer.forward(seconds);
    }

    public getTime() {
        return this.plyrPlayer.currentTime;
    }

    public getDuration() {
        return this.plyrPlayer.duration;
    }

    public setSpeed(speed: number) {
        this.plyrPlayer.speed = speed;
    }

    public setQuality(newQuality: number) {
        if (this.allQualityOptions.includes(newQuality) && newQuality !== this.selectedQuality) {
            this.plyrPlayer.quality = newQuality;
        }
    }

    public fullscreen(fullscreen: boolean) {
        if (fullscreen === true) {
            this.plyrPlayer.fullscreen.enter();
        } else {
            if (this.plyrPlayer && this.getFullscreenState()) {
                this.plyrPlayer.fullscreen.exit();
            }
        }
    }

    public setVolume(volume: number) {
        this.plyrPlayer.volume = volume;
    }

    public getFullscreenState() {
        return this.plyrPlayer.fullscreen.active;
    }

    private initializeSeenSeconds(length: number) {
        this.seenSegments = Array(Math.round(length) || 0).fill(false);
    }

    private setSeenPercentage(currentTime, duration) {
        if (this.seenSegments.length <= 1) {
            this.initializeSeenSeconds(duration);
        }

        currentTime = Math.round(currentTime);

        if (this.seenSegments.length >= currentTime && !this.seenSegments[currentTime]) {
            this.updateSeenPercentage(currentTime);
        }
    }

    private updateSeenPercentage(currentTime: number) {
        this.seenSegments[currentTime] = true;
        let seenPercentage = 0;
        let seenCount = 0;

        if (this.seenSegments.length > 1) {
            seenCount = this.seenSegments.filter(isSeenSecond => isSeenSecond).length;
            const totalCount = this.seenSegments.length;

            seenPercentage = (seenCount / totalCount) * 100;
        }
    }

    public getDeltaSeenTime() {
        return this.seenSegments.filter(isSeenSecond => isSeenSecond).length;
    }

    private async determinePlayerTech(element: ElementRef) {
        if (shaka.Player.isBrowserSupported()) {
            this.playerTech = PlayerTech.SHAKA;
            // Install built-in polyfills
            shaka.polyfill.installAll();
            element.nativeElement.setAttribute('webkit-playsinline', 'webkit-playsinline'); // Fix fullscreen problem on IOS 8 and 9
            element.nativeElement.setAttribute('playsinline', 'playsinline'); // Fix fullscreen problem on IOS 10
            this.shakaProbe = await shaka.Player.probeSupport();
            // console.log(this.shakaProbe);

            this.shakaPlayer = new shaka.Player(element.nativeElement);
            this.shakaPlayer.configure({
                streaming: {
                    bufferingGoal: 600,
                    bufferBehind: 300
                },
                abr: {
                    defaultBandwidthEstimate: 1000000,
                    bandwidthDowngradeTarget: 0.95,
                    bandwidthUpgradeTarget: 0.85,
                    enabled: true
                },
                preferredVideoCodecs: ['av01', 'vp09', 'hev1', 'avc1']
            });

            // Fired after the manifest has been parsed, but before anything else happens.
            // The manifest may contain streams that will be filtered out, at this stage of the loading process.
            this.shakaPlayer.addEventListener('manifestparsed', () => {
                // console.log('manifest parsed event', this.shakaPlayer.getVariantTracks());
            });
            // Fired when the state of abr has been changed. (Enabled or disabled).
            this.shakaPlayer.addEventListener('abrstatuschanged', () => {});
            // Fired when an automatic adaptation causes the active tracks to change.
            // Does not fire when the application calls selectVariantTrack(), selectTextTrack(), selectAudioLanguage(), or selectTextLanguage().
            this.shakaPlayer.addEventListener('adaptation', () => {
                const activeTrack = this.shakaPlayer
                    .getVariantTracks()
                    .find(t => t.active === true);
                this.setQuality(activeTrack.height);
            });
            // Fired when a call from the application caused a variant change.
            // Can be triggered by calls to selectVariantTrack() or selectAudioLanguage().
            // Does not fire when an automatic adaptation causes a variant change. (handled by 'adaptation' event)
            this.shakaPlayer.addEventListener('variantchanged', () => {
                this.qualityChangedSubject.next(this.selectedQuality);
            });
            // Triggers after metadata associated with the stream is found. Usually they are metadata of type ID3.
            this.shakaPlayer.addEventListener('metadata', () => {});
            // Fired when a playback error occurs.
            // this.shakaPlayer.addEventListener(
            //     'error',
            //     (
            //         type: string,
            //         detail: { severity: string; category: string; code: number; varArgs: any }
            //     ) => console.error(type, detail)
            // );
            this.shakaPlayer.getNetworkingEngine().registerRequestFilter((type, request) => {
                // request.allowCrossSiteCredentials = true;
                request.withCredentials = true;
            });
        }

        return this.shakaPlayer;
    }
}

