import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Comment } from '../../models/comment.model';

import * as commentsActions from '../actions/comments.actions';
import * as metaActions from '../actions/meta.actions';

const selectId = (entity: any) => entity._id;
export const commentsAdapter: EntityAdapter<Comment> = createEntityAdapter<Comment>({ selectId });

export interface CommentsState {
    comments: EntityState<Comment>;
}

export const commentsInitialState: CommentsState = {
    comments: commentsAdapter.getInitialState()
};

export const commentsReducer = createReducer(
    commentsInitialState,
    on(commentsActions.loadCommentsSuccess, (state, { payload }) => ({
        ...state,
        comments: commentsAdapter.setAll(payload, state.comments)
    })),
    on(commentsActions.newCommentSuccess, (state, { payload }) => ({
        ...state,
        comments: commentsAdapter.addOne({ ...payload, metadata: { isNew: true } }, state.comments)
    })),
    on(commentsActions.newCommentSuccess, (state, { payload }) => {
        if (payload.isReply) {
            const parentId = payload.parent._id;
            const comment: any = { ...state.comments?.entities[parentId] };

            return {
                ...state,
                comments: commentsAdapter.updateOne(
                    { id: comment._id, changes: { replies: [...comment.replies, payload._id] } },
                    state.comments
                )
            };
        } else {
            return state;
        }
    }),
    on(commentsActions.updateCommentSuccess, (state, { payload }) => ({
        ...state,
        comments: commentsAdapter.updateOne({ id: payload._id, changes: payload }, state.comments)
    })),
    on(commentsActions.deleteCommentSuccess, (state, { payload }) => ({
        ...state,
        comments: commentsAdapter.removeOne(payload._id, state.comments)
    })),
    on(metaActions.likeComment, (state, { payload }) => {
        const comment: any = { ...state.comments?.entities[payload.commentId] };

        return {
            ...state,
            comments: commentsAdapter.updateOne(
                {
                    id: payload.commentId,
                    changes: {
                        likes: ++comment.likes,
                        rating: ++comment.rating
                    }
                },
                state.comments
            )
        };
    }),
    on(metaActions.likeCommentSuccess, (state, { payload }) => ({
        ...state,
        comments: commentsAdapter.updateOne(
            {
                id: payload?.comment?._id,
                changes: payload?.comment
            },
            state.comments
        )
    })),
    on(metaActions.likeCommentFailure, (state, { payload }) => {
        const comment: any = { ...state.comments?.entities[payload.commentId] };

        return {
            ...state,
            comments: commentsAdapter.updateOne(
                {
                    id: payload.commentId,
                    changes: {
                        likes: --comment.likes,
                        rating: --comment.rating
                    }
                },
                state.comments
            )
        };
    }),
    on(metaActions.dislikeComment, (state, { payload }) => {
        const comment: any = { ...state.comments?.entities[payload.commentId] };

        return {
            ...state,
            comments: commentsAdapter.updateOne(
                {
                    id: payload.commentId,
                    changes: {
                        dislikes: ++comment.dislikes,
                        rating: --comment.rating
                    }
                },
                state.comments
            )
        };
    }),
    on(metaActions.dislikeCommentSuccess, (state, { payload }) => ({
        ...state,
        comments: commentsAdapter.updateOne(
            {
                id: payload.comment._id,
                changes: payload.comment
            },
            state.comments
        )
    })),
    on(metaActions.dislikeCommentFailure, (state, { payload }) => {
        const comment: any = { ...state.comments?.entities[payload.commentId] };

        return {
            ...state,
            comments: commentsAdapter.updateOne(
                {
                    id: payload.commentId,
                    changes: {
                        dislikes: --comment.dislikes,
                        rating: ++comment.rating
                    }
                },
                state.comments
            )
        };
    }),
    on(metaActions.deleteRatedComment, (state, { payload }) => {
        const comment: any = { ...state.comments?.entities[payload.commentId] };

        return {
            ...state,
            comments: commentsAdapter.updateOne(
                {
                    id: payload.commentId,
                    changes: {
                        likes: payload.isLiked ? --comment.likes : comment.likes,
                        dislikes: payload.isLiked ? comment.dislikes : --comment.dislikes,
                        rating: payload.isLiked ? --comment.rating : ++comment.rating
                    }
                },
                state.comments
            )
        };
    }),
    on(metaActions.deleteRatedCommentSuccess, (state, { payload }) => ({
        ...state,
        comments: commentsAdapter.updateOne(
            {
                id: payload.comment._id,
                changes: payload.comment
            },
            state.comments
        )
    }))
);
