import { createAction, props } from '@ngrx/store';
import { Comment } from '../../models/comment.model';
import { APIError } from '../../models/error.model';

export enum CommentsActionsTypes {
    LoadComments = '[Video Guide Page] Load Comments',
    LoadCommentsSuccess = '[Users API] Load Comments Success',
    LoadCommentsFailure = '[Users API] Load Comments Failure',

    NewComment = '[Video Guide Page] New Comment',
    NewCommentSuccess = '[Users API] New Comment Success',
    NewCommentFailure = '[Users API] New Comment Failure',

    UpdateComment = '[Video Guide Page] Update Comment',
    UpdateCommentSuccess = '[Users API] Update Comment Success',
    UpdateCommentFailure = '[Users API] Update Comment Failure',

    DeleteComment = '[Video Guide Page] Delete Comment',
    DeleteCommentSuccess = '[Users API] Delete Comment Success',
    DeleteCommentFailure = '[Users API] Delete Comment Failure'
}

export const loadComments = createAction(
    CommentsActionsTypes.LoadComments,
    props<{ payload: { videoGuideId: string } }>()
);
export const loadCommentsSuccess = createAction(
    CommentsActionsTypes.LoadCommentsSuccess,
    props<{ payload: Comment[] }>()
);
export const loadCommentsFailure = createAction(
    CommentsActionsTypes.LoadCommentsFailure,
    props<{ payload: APIError }>()
);

export const newComment = createAction(
    CommentsActionsTypes.NewComment,
    props<{
        payload: {
            content: string;
            parent?: string;
            isReply?: boolean;
            replyUser?: string;
            replyCommentId?: string;
        };
    }>()
);
export const newCommentSuccess = createAction(
    CommentsActionsTypes.NewCommentSuccess,
    props<{ payload: Comment }>()
);
export const newCommentFailure = createAction(
    CommentsActionsTypes.NewCommentFailure,
    props<{ payload: APIError }>()
);

export const updateComment = createAction(
    CommentsActionsTypes.UpdateComment,
    props<{
        payload: {
            commentId: string;
            userId: string;
            data: {
                content: string;
            };
        };
    }>()
);
export const updateCommentSuccess = createAction(
    CommentsActionsTypes.UpdateCommentSuccess,
    props<{ payload: Comment }>()
);
export const updateCommentFailure = createAction(
    CommentsActionsTypes.UpdateCommentFailure,
    props<{ payload: APIError }>()
);

export const deleteComment = createAction(
    CommentsActionsTypes.DeleteComment,
    props<{
        payload: {
            commentId: string;
            userId: string;
        };
    }>()
);
export const deleteCommentSuccess = createAction(
    CommentsActionsTypes.DeleteCommentSuccess,
    props<{ payload: Comment }>()
);
export const deleteCommentFailure = createAction(
    CommentsActionsTypes.DeleteCommentFailure,
    props<{ payload: APIError }>()
);
